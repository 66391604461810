import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ItemsWrapper,
  ItemsPaginator,
  ItemsSeperator,
} from '@components/Articles/Items/index.style'
import Item from '../Item'
import NoResults from '../NoResults'
import Spacer from '@components/Spacer'
import Button from '@components/Button'

const Items = ({ tools, activeAssetFilter, hasAnchorId }) => {
  const [itemCount, setItemCount] = useState(6)

  const addTools = useCallback(() => {
    setItemCount(itemCount + 6)
  }, [itemCount])

  return (
    <ItemsWrapper id="marketing-list">
      {tools.length ? (
        hasAnchorId ? (
          React.Children.toArray(
            tools.map((tool, i) => {
              return (
                <Item
                  itemIndex={i}
                  tool={tool}
                  activeAssetFilter={activeAssetFilter}
                />
              )
            })
          )
        ) : (
          React.Children.toArray(
            tools.slice(0, itemCount).map((tool, i) => {
              return (
                <Item
                  itemIndex={i}
                  tool={tool}
                  activeAssetFilter={activeAssetFilter}
                />
              )
            })
          )
        )
      ) : (
        <NoResults />
      )}

      {itemCount < tools.length && !hasAnchorId && (
        <ItemsPaginator>
          <ItemsSeperator />
          <Spacer size={[36, 114]} />
          <Button type="button" onClick={addTools}>
            Load More
          </Button>
        </ItemsPaginator>
      )}
    </ItemsWrapper>
  )
}

Items.propTypes = {
  tools: PropTypes.array,
  activeAssetFilter: PropTypes.string,
  hasAnchorId: PropTypes.bool,
}

export default Items
