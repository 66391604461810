import React, { useEffect, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import { useStore } from '@Store'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import TitleContent from '@components/TitleContent'
import { getTagsFromItems, scrollListAnchor } from '@utils'
import List from '@components/Marketing/List'
import Spacer from '@components/Spacer'

const MarketingToolsPage = ({ data, location }) => {
  const [store] = useStore()
  const { smoothScroll } = store

  const [hasAnchor, setHasAnchor] = useState(false)

  const intro = {
    title: 'Marketing Tools',
    content: {
      raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Browse our comprehensive marketing library, using the filters to help narrow your search. Or, simply cruise through all of the ready-to-use marketing assets available to you!","marks":[],"data":{}}]}]}',
      references: [],
    },
    alignmentDesktop: 'Stack',
  }

  const slug = 'marketing-tools'
  const seo = {
    seoTitle: 'Marketing Tools',
    seoDescription: null,
    seoImage: {
      file: {
        url: '//images.ctfassets.net/1euybtmvarct/1SylPDCX358ykaFsfXoaSy/831417929768b270b4a9e6711c6a6126/beach.jpg',
      },
    },
  }

  let toolTags = getTagsFromItems(
    data.allContentfulMarketingToolkit.nodes,
    'Marketing Tools:'
  )

  // Map asset links to get tag items ready
  const assetLists = data.allContentfulMarketingToolkit.nodes
    .map(item => item.assetLists)
    .flat()

  let assetTags = getTagsFromItems(assetLists, 'Marketing Assets:')

  // Sort tag arrays
  toolTags.sort()
  assetTags.sort()

  /**
   * Scroll to marketing tool
   */
  const scrollToMarketingTool = useCallback(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const list = urlParams.get('list')
    const id = urlParams.get('id')
    const prefix = '#marketing-'

    let timeout

    if (id) {
      setHasAnchor(true)
    }

    if (list && smoothScroll) {
      timeout = setTimeout(() => {
        scrollListAnchor(id, smoothScroll, prefix)
      }, 1000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [smoothScroll])

  useEffect(() => {
    scrollToMarketingTool()
  }, [scrollToMarketingTool])

  return (
    <>
      <Seo data={seo} slug={slug} />
      <TitleContent
        title={intro.title}
        content={intro.content}
        alignment={intro.alignmentDesktop}
      />
      <List
        toolTags={toolTags}
        assetTags={assetTags}
        marketingTools={data.allContentfulMarketingToolkit.nodes}
        hasAnchorId={hasAnchor}
      />
      <Spacer size={100} />
      <Footer location={location} />
    </>
  )
}

export default MarketingToolsPage

// Query for marketing tools sorted by pinned and then title alphabetically
export const pageQuery = graphql`
  query MarketingToolsPage {
    allContentfulMarketingToolkit(
      sort: { fields: [pinned, title], order: [DESC, ASC] }
    ) {
      nodes {
        contentful_id
        metadata {
          tags {
            name
          }
        }
        title
        pinned
        image {
          gatsbyImageData(width: 720, height: 600)
          description
        }
        assetLists {
          title
          metadata {
            tags {
              name
            }
          }
          links {
            ...Link
          }
        }
      }
    }
  }
`
