import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { ListWrapper } from './index.style'
import Filters from '@components/Articles/Filters'
import SecondaryFilters from '../SecondaryFilters'
import Items from '../Items'
import { checkFilterMatch, getTagsFromHash, anchorScroll } from '@utils'
import Spacer from '@components/Spacer'
import { useStore } from '@Store'

const List = ({ toolTags, assetTags, marketingTools, hasAnchorId }) => {
  const [filteredMarketingTools, setFilteredMarketingTools] =
    useState(marketingTools)
  const [activeFilters, setActiveFilters] = useState([])
  const [activeAssetFilter, setActiveAssetFilter] = useState('')
  const listRef = useRef()
  const [store] = useStore()
  const { smoothScroll } = store

  const updateFilters = tags => {
    // Reset secondary if primary filter is changed
    setActiveAssetFilter('')

    setActiveFilters(tags)
  }

  useEffect(() => {
    let filterLists = marketingTools

    // Filter through mapped asset tags
    const checkFilterAssets =
      activeAssetFilter &&
      marketingTools.map(tool =>
        tool.assetLists
          .map(assetList =>
            assetList.metadata.tags
              .filter(tag => activeAssetFilter.includes(tag.name))
              .flat()
          )
          .flat()
      )

    // Reset filter based on maketing asset tools if necessary
    if (activeAssetFilter) {
      filterLists = []

      // Push to updated filter array
      marketingTools.forEach((tool, i) => {
        checkFilterAssets[i].length && filterLists.push(tool)
      })
    }

    // Filter as normal based on match
    const matchingResults = filterLists.flatMap(tool => {
      const filterMatch =
        activeFilters.length === 0 ||
        checkFilterMatch(tool.metadata.tags, activeFilters)

      return filterMatch ? [tool] : []
    })

    setFilteredMarketingTools(matchingResults)
  }, [marketingTools, activeFilters, activeAssetFilter])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const filtersParam = params.get('filters')
    const assetsParam = params.get('assets')

    if (filtersParam) {
      const tags = getTagsFromHash(toolTags, filtersParam)

      if (tags) {
        const activeToolTags = tags.map(tag => tag.name)

        setActiveFilters(activeToolTags)
      }
    }

    if (assetsParam) {
      const tags = getTagsFromHash(assetTags, assetsParam)

      if (tags) {
        const activeAssetTags = tags.map(tag => tag.name)

        // This just expects a string, so set it to the first item
        setActiveAssetFilter(activeAssetTags[0])
      }
    }

    if (activeFilters.length || activeAssetFilter) {
      if (smoothScroll) {
        setTimeout(() => {
          anchorScroll(smoothScroll, listRef.current?.offsetTop - 110)
        }, 1000)
      }
    }
  }, [
    toolTags,
    assetTags,
    setFilteredMarketingTools,
    smoothScroll,
    activeFilters,
    activeAssetFilter,
  ])

  return (
    <ListWrapper ref={listRef}>
      <Container>
        <Spacer size={[28, 48]} />
        <Grid>
          {toolTags && (
            <GridItem
              tiny={assetTags ? 6 : 12}
              tabletP={assetTags ? 6 : 12}
              desk={12}
            >
              <Filters
                activeFilters={activeFilters}
                setActiveFilters={updateFilters}
                tags={toolTags}
              />
            </GridItem>
          )}

          {assetTags && (
            <GridItem tiny={6} tabletP={6} desk={3}>
              <SecondaryFilters
                activeAssetFilter={activeAssetFilter}
                setActiveAssetFilter={setActiveAssetFilter}
                tags={assetTags}
              />
            </GridItem>
          )}

          <GridItem tabletP={12} desk={assetTags ? 9 : 12}>
            <Items
              tools={filteredMarketingTools}
              activeAssetFilter={activeAssetFilter}
              hasAnchorId={hasAnchorId}
            />
          </GridItem>
        </Grid>
      </Container>
    </ListWrapper>
  )
}

List.propTypes = {
  toolTags: PropTypes.array,
  assetTags: PropTypes.array,
  marketingTools: PropTypes.array,
  hasAnchorId: PropTypes.bool,
}

export default List
