import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const FiltersWrapper = styled.div`
  padding-bottom: 7.2rem;
  text-align: right;

  ${mq.desk} {
    text-align: left;
  }
`

export const FiltersList = styled.ul`
  display: none;
  list-style-type: none;

  ${mq.desk} {
    display: flex;
    flex-direction: column;
  }
`

export const Filter = styled.li`
  margin-bottom: 2.4rem;
`

export const FilterRadioLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${clamp('font-size', 18, 21)}
  transition: color .3s ${cubicBezier.inOut};

  &:hover {
    color: ${colors.blue}
  }
`

export const FilterRadioInput = styled.input`
  margin-right: 1.8rem;
  cursor: pointer;
  accent-color: ${colors.blue};
`