import { colors } from '@styles/vars/colors.style'
import React from 'react'

const IconArrowSelect = ({
  width = 12,
  height = 7,
  stroke = colors.blue,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 7"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M11 1L6 6M6 6L1 1M6 6L6 5.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconArrowSelect
