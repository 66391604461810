import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import styled from 'styled-components'

export const ItemsWrapper = styled.div`
  ${clamp('padding-bottom', 64, 100)};
`

export const ItemContent = styled.div`
  padding-bottom: 7.2rem;
  ${clamp('padding-top', 42, 36)};
`

export const ItemsPaginator = styled.div`
  text-align: center;
`

export const ItemsSeperator = styled.hr`
  background-color: ${colors.darkblue};
  border: none;
  display: block;
  height: 0.1rem;
  width: 100%;
`
