import React from 'react'
import PropTypes from 'prop-types'
import {
  ItemWrapper,
  ItemContent
} from '@components/Deployments/Item/index.style'
import { Heading2, TextBodySmall } from '@components/TextStyles'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { useInView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'
import AnimateSplitText from '@components/animation/AnimateSplitText'

const NoResults = ({ title, text }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })

  return (
    <ItemWrapper ref={ref} inView={inView}>
      <Grid>
        <GridItem desk={12}>
          <ItemContent>
            <Heading2>
              <AnimateSplitText>
                {title}
              </AnimateSplitText>
            </Heading2>
            <AnimateSplitText>
              <TextBodySmall maxWidth={22}>
                {text}
              </TextBodySmall>
            </AnimateSplitText>
          </ItemContent>
        </GridItem>
      </Grid>
    </ItemWrapper>
  )
}

NoResults.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

NoResults.defaultProps = {
  title: "No Results",
  text: "Nothing found in all of our current brochures and supporting documents."
}

export default NoResults