import { colors } from '@styles/vars/colors.style'
import { font } from '@styles/vars/font.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const FiltersWrapper = styled.div`
  padding-bottom: 7.2rem;
`

export const FiltersButtons = styled.ul`
  display: none;
  list-style-type: none;

  ${mq.desk} {
    display: flex;
  }
`

export const Filter = styled.li`
  margin-right: 1.8rem;
`

export const FiltersSelect = styled.div`
  display: inline-block;
  position: relative;
  max-width: 20rem;

  ${mq.desk} {
    display: none;
  }

  select {
    width: 100%;
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: 2.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const FiltersSelectInput = styled.select`
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid ${colors.blue};
  border-radius: 2.2rem;
  color: ${colors.dark};
  font-size: 1.6rem;
  font-weight: ${font.secondary.weight.medium};
  letter-spacing: 0.01em;
  padding: 0.9rem 4.4rem 0.9rem 2.6rem;
  white-space: nowrap;
`
