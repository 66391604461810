import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ItemWrapper,
  ItemContent,
  ItemAssetButton,
  ItemAssets,
} from '@components/Deployments/Item/index.style'
import { ItemAssetToolList } from './index.style'
import { Heading2, Heading4, TextBodySmall } from '@components/TextStyles'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RoundedImage from '@components/RoundedImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import IconArrowDown from '@svgs/IconArrowDown'
import IconArrowTop from '@svgs/IconArrowTop'
import Link from '@components/Link'
import { ListItem, UnorderedList } from '@components/RichText/index.style'
import { AccordionItem } from '@components/Accordion'
import AnimateImage from '@components/animation/AnimateImage'
import { useInView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const Item = ({ tool, activeAssetFilter, itemIndex }) => {
  const [open, setOpen] = useState(false)
  const [assets, setAssets] = useState(tool.assetLists)

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })

  useEffect(() => {
    setOpen(false)

    if (activeAssetFilter) {
      // Filter assets based on filter term
      const filteredAssets = tool.assetLists.filter(asset =>
        asset.metadata.tags.some(tag =>
          activeAssetFilter !== '' ? tag.name === activeAssetFilter : 1
        )
      )

      setAssets(filteredAssets)

      // Set first item open on secondary asset change
      if (itemIndex < 1 && activeAssetFilter !== '') {
        setOpen(true)
      }
    } else {
      setAssets(tool.assetLists)
    }
  }, [tool, itemIndex, activeAssetFilter])

  return (
    <ItemWrapper
      ref={ref}
      inView={inView}
      id={`marketing-${tool.contentful_id}`}
    >
      <Grid>
        <GridItem tabletP={4} desk={3}>
          <RoundedImage>
            <AnimateImage delay={0} hover={false}>
              <GatsbyImage
                image={tool.image.gatsbyImageData}
                alt={tool.image.description}
              />
            </AnimateImage>
          </RoundedImage>
        </GridItem>
        <GridItem tabletP={5} tabletPAlign={'flex-end'} desk={6}>
          <ItemContent>
            <Heading2>
              <AnimateSlideIn>{tool.title}</AnimateSlideIn>
            </Heading2>
            <AnimateSplitText>
              <TextBodySmall maxWidth={22}>
                Everything you need for all of our current brochures and
                supporting documents.
              </TextBodySmall>
            </AnimateSplitText>
          </ItemContent>
        </GridItem>
        <GridItem tabletP={3} desk={3} tabletPAlign={'flex-end'}>
          <Spacer size={30} />
          <AnimateFadeIn>
            <ItemAssetButton>
              <Button
                iconRight={open ? <IconArrowTop /> : <IconArrowDown />}
                iconOrientation="vertical"
                onClick={() => setOpen(!open)}
              >
                {open ? 'Close' : 'View Assets'}
              </Button>
            </ItemAssetButton>
          </AnimateFadeIn>
        </GridItem>
      </Grid>

      <ItemAssets>
        <Grid>
          {React.Children.toArray(
            assets.map(assetList => {
              return (
                <GridItem tabletP={4} desk={4}>
                  <AccordionItem open={open}>
                    <ItemAssetToolList>
                      <Spacer size={[40, 72]} />
                      <Heading4>
                        <AnimateSplitText customAnimate={open}>
                          {assetList.title}
                        </AnimateSplitText>
                      </Heading4>
                      <Spacer size={12} />
                      <UnorderedList>
                        {assetList.links &&
                          React.Children.toArray(
                            assetList.links.map(link => {
                              return (
                                <AnimateFadeIn customAnimate={open}>
                                  <ListItem>
                                    <Link
                                      page={link.page && link.page}
                                      url={
                                        link.url && link.url
                                          ? link.url
                                          : link.resource?.file?.url
                                      }
                                      textLink={true}
                                    >
                                      <AnimateSplitText customAnimate={open}>
                                        {link.text}
                                      </AnimateSplitText>
                                    </Link>
                                  </ListItem>
                                </AnimateFadeIn>
                              )
                            })
                          )}
                      </UnorderedList>
                    </ItemAssetToolList>
                  </AccordionItem>
                </GridItem>
              )
            })
          )}
        </Grid>
      </ItemAssets>
    </ItemWrapper>
  )
}

Item.propTypes = {
  tool: PropTypes.object.isRequired,
  activeAssetFilter: PropTypes.string,
  itemIndex: PropTypes.number,
}

export default Item
