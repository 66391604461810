import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  FiltersWrapper,
  FiltersButtons,
  FiltersSelect,
  FiltersSelectInput,
  Filter,
} from './index.style'
import Button from '@components/Button'
import { removeStringFromArray } from '@utils'
import IconArrowSelect from '@svgs/IconArrowSelect'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const Filters = ({ activeFilters, setActiveFilters, tags }) => {
  const selectRef = useRef()

  const removeFilters = () => {
    setActiveFilters([])
  }

  const toggleFilter = tag => {
    if (activeFilters.includes(tag)) {
      const currentFilters = removeStringFromArray(activeFilters, tag)
      setActiveFilters(currentFilters)
    } else {
      setActiveFilters([...activeFilters, tag])
    }
  }

  const handleSelect = () => {
    if (selectRef.current.value) {
      setActiveFilters([selectRef.current.value])
    } else {
      removeFilters()
    }
  }

  return (
    <FiltersWrapper>
      <FiltersSelect>
        <FiltersSelectInput
          defaultValue="year"
          onChange={handleSelect}
          ref={selectRef}
        >
          <option value="year" disabled>
            Category
          </option>
          <option value="">Show All</option>
          {React.Children.toArray(
            tags.map(tag => (
              <option value={tag.name}>{tag.formattedName}</option>
            ))
          )}
        </FiltersSelectInput>
        <IconArrowSelect />
      </FiltersSelect>
      <FiltersButtons>
        <Filter>
          <AnimateFadeIn>
            <Button
              type="button"
              onClick={removeFilters}
              variant={activeFilters.length === 0 ? `secondary` : `tertiary`}
            >
              Show All
            </Button>
          </AnimateFadeIn>
        </Filter>
        {React.Children.toArray(
          tags.map(tag => (
            <Filter>
              <AnimateFadeIn>
                <Button
                  type="button"
                  onClick={() => toggleFilter(tag.name)}
                  variant={
                    activeFilters.includes(tag.name) ? `secondary` : `tertiary`
                  }
                >
                  {tag.formattedName}
                </Button>
              </AnimateFadeIn>
            </Filter>
          ))
        )}
      </FiltersButtons>
    </FiltersWrapper>
  )
}

Filters.propTypes = {
  activeFilters: PropTypes.array,
  setActiveFilters: PropTypes.func,
  tags: PropTypes.array,
}

export default Filters
