import React from 'react'
import PropTypes from 'prop-types'
import {
  FiltersWrapper,
  FiltersList,
  Filter,
  FilterRadioLabel,
  FilterRadioInput
} from './index.style'
import {
  FiltersSelect,
  FiltersSelectInput
} from '@components/Articles/Filters/index.style'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import IconArrowSelect from '@svgs/IconArrowSelect'

const SecondaryFilters = ({
  activeAssetFilter,
  setActiveAssetFilter,
  tags
}) => {
  const removeFilters = () => {
    setActiveAssetFilter('')
  }

  const handleChange = (e) => {
    if (e.target.value) {
      setActiveAssetFilter(e.target.value)
    } else {
      removeFilters()
    }
  }

  return (
    <FiltersWrapper>
      <FiltersSelect>
        <FiltersSelectInput
          defaultValue="type"
          onChange={handleChange}
        >
          <option value="type" disabled>
            Asset Type
          </option>
          <option value="">Show All</option>
          {React.Children.toArray(
            tags.map(tag => (
              <option value={tag.name}>{tag.formattedName}</option>
            ))
          )}
        </FiltersSelectInput>
        <IconArrowSelect />
      </FiltersSelect>

      <FiltersList>
        <Filter>
          <AnimateFadeIn>
            <FilterRadioLabel
              htmlFor="show-all"
            >
              <FilterRadioInput
                onChange={removeFilters}
                checked={activeAssetFilter === ''}
                value=""
                id="show-all"
                name="assetType"
                type="radio"
              />
              <span>Show All</span>
            </FilterRadioLabel>
          </AnimateFadeIn>
        </Filter>
        
        {React.Children.toArray(
          tags.map((tag) => (
            <Filter>
              <AnimateFadeIn>
                <FilterRadioLabel
                  htmlFor={tag.name}
                >
                  <FilterRadioInput
                    onChange={handleChange}
                    checked={activeAssetFilter === tag.name}
                    value={tag.name}
                    id={tag.name}
                    name="assetType"
                    type="radio"
                  />
                  <span>{tag.formattedName}</span>
                </FilterRadioLabel>
              </AnimateFadeIn>
            </Filter>
          ))
        )}
      </FiltersList>
    </FiltersWrapper>
  )
}

SecondaryFilters.propTypes = {
  activeAssetFilter: PropTypes.string,
  setActiveAssetFilter: PropTypes.func,
  tags: PropTypes.array
}

export default SecondaryFilters